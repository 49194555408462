import React from "react"
import Layout from "../components/layout";
import {graphql} from "gatsby";

export default (props) => {
   return (
      <Layout>
         <div dangerouslySetInnerHTML={{ __html: props.data.markdownRemark.html }} className={'frank-text'}/>
      </Layout>
   )
}


export const contactPageQuery = graphql`
    query contactPage {
        markdownRemark(frontmatter: { pageContent: { eq: "contact" } }) {
            html
            frontmatter {
                title
            }
        }
    }
`